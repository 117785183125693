import { Header } from "../Header/Header";
import { ScrollArea } from "./ScrollArea";

export const Layout = ({ children }) => {
  return (
    <div className="bg-neutral-100 text-foreground font-sans">
      <Header />
      <div className="flex w-full pt-16 h-full">
        <div className="flex-1 relative h-full min-h-screen">
          <div className="h-screen w-full flex flex-col pb-16">
            <div className="flex-1 w-full overflow-auto">
              <ScrollArea className="h-full w-full">
                <div className="flex flex-col justify-center items-center">
                  <div className="w-10/12 xl:w-[1028px]">{children}</div>
                </div>
              </ScrollArea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
