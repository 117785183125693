import { useWidgets } from "../../../hooks/useWidgets";
import { WidgetTemplate } from "../Templates/WidgetTemplate";
import { NonconformityChart } from "./NonconformityChart";
import { NonconformityStatistics } from "./NonconformityStatistcs";
import { useQuery } from "@tanstack/react-query";
import {
  fetchNonconformityActions,
  fetchNonconformityReports,
  type NonconformityActionsResponse,
} from "../../../api/nonconformity";
import { NonconformityActions } from "./NonconformityActions";
import { useStore } from "../../../store/useStore";
import { WidgetErrorTemplate } from "../Templates/WidgetErrorTemplate";

interface InjuryWidgetProps {
  id: string;
}

export const NonconformityWidget = ({ id }: InjuryWidgetProps) => {
  const { widget } = useWidgets(id);
  const { customizeEnabled } = useStore();

  const actionsQuery = useQuery<NonconformityActionsResponse>({
    queryKey: ["nonconformity-actions"],
    queryFn: fetchNonconformityActions,
    retry: false,
    enabled: !customizeEnabled,
  });

  const reportsQuery = useQuery({
    queryKey: ["nonconformity-ongoing-reports"],
    queryFn: fetchNonconformityReports,
    retry: false,
    enabled: !customizeEnabled,
  });

  const refetchCallback = () => {
    Promise.all([actionsQuery.refetch(), reportsQuery.refetch()]);
  };

  if (!widget) {
    console.info("Nonconformitywidget: widget not found id:", id);
    return null;
  }

  const { title, visible, expanded } = widget;

  if (actionsQuery.isError || reportsQuery.isError) {
    return (
      <WidgetErrorTemplate title={title} refetchCallback={refetchCallback} />
    );
  }

  return (
    <WidgetTemplate
      id={id}
      title={title}
      pageLink={"handler/general"}
      visible={visible}
      data-testid="nonconformity-widget"
      refetchCallback={refetchCallback}
      isRefetching={actionsQuery.isRefetching || reportsQuery.isRefetching}
    >
      <NonconformityStatistics />
      <NonconformityChart />

      {expanded && <NonconformityActions />}
    </WidgetTemplate>
  );
};
