import { TaskListItem } from "../Templates/TaskListItem";
import { useQueryClient } from "@tanstack/react-query";
import type { NonconformityActionsResponse } from "../../../api/nonconformity";
import { Skeleton } from "../../ui/Skeleton";

// Parse a date string in the format "dd.mm.yyyy" into a Date object
const parseDate = (dateString: string): Date | null => {
  const [day, month, year] = dateString.split(".").map(Number);
  if (day && month && year) {
    return new Date(year, month - 1, day); // Month is 0-indexed
  }
  return null;
};

export const NonconformityActions = () => {
  const queryClient = useQueryClient();
  const actionsResponse =
    queryClient.getQueryData<NonconformityActionsResponse>([
      "nonconformity-actions",
    ]);

  return (
    <div>
      <h3 className="text-base font-bold my-2">Actions</h3>
      {!actionsResponse && <Skeleton className="h-80 w-full" />}
      {actionsResponse?.relatedActions
        .filter((action) => action.dueDate) // Filter out actions without a due date
        .sort((action1, action2) => {
          const date1 = parseDate(action1.dueDate);
          const date2 = parseDate(action2.dueDate);
          if (date1 && date2) {
            return date1.getTime() - date2.getTime();
          }
          return 0; // Handle cases where dates are invalid
        })
        .slice(0, 5)
        .map((action) => {
          const dueDate = parseDate(action.dueDate); // Use parseDate function
          return (
            <TaskListItem
              taskName={action.actionName}
              taskInfo={action.caseName}
              dueDate={dueDate}
              key={action.actionName}
            />
          );
        })}
    </div>
  );
};
