import { useQueryClient } from "@tanstack/react-query";
import type {
  NonconformityActionsResponse,
  NonconformityReportsResponse,
} from "../../../api/nonconformity";
import { WidgetStatisticsBox } from "../Templates/WidgetStatisticsBox";

export const NonconformityStatistics = () => {
  const queryClient = useQueryClient();
  const actionsResponse =
    queryClient.getQueryData<NonconformityActionsResponse>([
      "nonconformity-actions",
    ]);

  const ongoingReports = queryClient.getQueryData<NonconformityReportsResponse>(
    ["nonconformity-ongoing-reports"],
  );

  return (
    <div className="flex flex-col sm:flex-row justify-around border rounded-lg border-gray-200">
      <WidgetStatisticsBox
        value={ongoingReports?.reportCount}
        label="Ongoing"
      />
      <WidgetStatisticsBox
        className="sm:border-x-2"
        value={26}
        label="Urgent"
      />
      {
        <WidgetStatisticsBox
          value={actionsResponse?.relatedActions.length}
          label="Actions"
        />
      }
    </div>
  );
};
