import { useTranslation } from "react-i18next";
import { type TimeFilterType, useStore } from "../../store/useStore";
import { DatePickerWithRangePopover } from "../ui/DatePickerWithRange";
import {
  type DetailedHTMLProps,
  type HTMLAttributes,
  type ReactNode,
  useState,
} from "react";
import type { DateRange } from "react-day-picker";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { cn } from "../../lib/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ChevronIcon } from "../../assets/icons/ChevronIcon";

dayjs.extend(utc);
dayjs.extend(timezone);

interface TimeFilterItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  selected?: boolean;
}
export const TimeFilterItem = ({
  selected,
  children,
  ...props
}: TimeFilterItemProps) => {
  return (
    <div
      className={cn(
        "w-full hover:bg-accent py-1.5 pl-8 pr-2 text-sm text-left h-7 rounded-none cursor-pointer",
        selected && "bg-accent text-foreground",
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const TimeFilter = () => {
  const { t } = useTranslation();

  const [date, setDate] = useState<DateRange | undefined>();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { currentTimeFilter, setTimeFilter } = useStore();
  const isMobile = useIsMobile();
  const userTimezone = dayjs.tz.guess();

  const setDateRange = (timeFilterValue: TimeFilterType) => {
    const now = dayjs().tz(userTimezone).endOf("day"); // Get the end of today in the user's timezone
    const currentMonth = now.month(); // 0-based month

    switch (timeFilterValue) {
      case "last4Weeks": {
        const last4WeeksStart = now.subtract(1, "month").startOf("day");
        const lastWeekDateRange = {
          from: last4WeeksStart.toDate(),
          to: now.toDate(),
        };
        setDate(lastWeekDateRange);
        setTimeFilter(timeFilterValue, {
          from: last4WeeksStart.format(),
          to: now.format(),
        });
        setPopoverOpen(false);
        break;
      }
      case "thisMonth": {
        const startOfMonth = now.startOf("month");
        const lastMonthDateRange = {
          from: startOfMonth.toDate(),
          to: now.toDate(),
        };
        setDate(lastMonthDateRange);
        setTimeFilter(timeFilterValue, {
          from: startOfMonth.format(),
          to: now.format(),
        });
        setPopoverOpen(false);

        break;
      }
      case "thisQuarter": {
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
        const startOfQuarter = now.month(quarterStartMonth).startOf("month");
        const thisQuarterDateRange = {
          from: startOfQuarter.toDate(),
          to: now.toDate(),
        };
        setDate(thisQuarterDateRange);
        setTimeFilter(timeFilterValue, {
          from: startOfQuarter.format(),
          to: now.format(),
        });
        setPopoverOpen(false);
        break;
      }
      case "thisTertiary": {
        const tertialStartMonth = Math.floor(currentMonth / 4) * 4;
        const startOfTertial = now.month(tertialStartMonth).startOf("month");
        const thisTertiaryDateRange = {
          from: startOfTertial.toDate(),
          to: now.toDate(),
        };
        setDate(thisTertiaryDateRange);
        setTimeFilter(timeFilterValue, {
          from: startOfTertial.format(),
          to: now.format(),
        });
        setPopoverOpen(false);
        break;
      }
      case "thisYear": {
        const thisYearStart = now.startOf("year");
        const thisYearDateRange = {
          from: thisYearStart.toDate(),
          to: now.toDate(),
        };
        setDate(thisYearDateRange);
        setTimeFilter(timeFilterValue, {
          from: thisYearStart.format(),
          to: now.format(),
        });
        setPopoverOpen(false);
        break;
      }
      case "custom": {
        const customStart = dayjs(date.from).tz(userTimezone).startOf("day");
        const customEnd = dayjs(date.to).tz(userTimezone).endOf("day");
        setTimeFilter(timeFilterValue, {
          from: customStart.format(),
          to: customEnd.format(),
        });
        setPopoverOpen(false);
        break;
      }
      default: {
        // Defaults to all time
        setTimeFilter(timeFilterValue, {
          from: undefined,
          to: now.format(),
        });
        setPopoverOpen(false);
        break;
      }
    }
  };

  const timeFilterValues: TimeFilterType[] = [
    "last4Weeks",
    "thisMonth",
    "thisQuarter",
    "thisTertiary",
    "thisYear",
    "allTime",
  ];

  if (isMobile) {
    // This component is not used on mobile
    return null;
  }

  return (
    <Popover open={popoverOpen} onOpenChange={(open) => setPopoverOpen(open)}>
      <PopoverTrigger className="bg-background w-64 h-10 border-black border-2 text-left px-9">
        <div className="flex flex-row justify-between items-center">
          {t(`${currentTimeFilter.timeFilter}`)}
          {popoverOpen ? (
            <ChevronIcon direction="up" />
          ) : (
            <ChevronIcon direction="down" />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="p-1 w-64">
        {timeFilterValues.map((timeFilterValue: TimeFilterType) => (
          <TimeFilterItem
            key={timeFilterValue}
            onClick={() => {
              setDateRange(timeFilterValue);
            }}
            selected={currentTimeFilter.timeFilter === timeFilterValue}
          >
            {t(`${timeFilterValue}`)}
          </TimeFilterItem>
        ))}
        <DatePickerWithRangePopover
          date={date}
          setDate={setDate}
          selected={currentTimeFilter.timeFilter === "custom"}
          onCustomSelect={() => {
            setDateRange("custom");
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
