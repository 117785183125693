import { Search } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store/useStore";
import { Navigation } from "../Navigation/Navigation";
import { Input } from "../ui/Input";
import { SamsvarLogo } from "../ui/SamsvarLogo";
import { Separator } from "../ui/Separator";
import { CommandMenu } from "./CommandMenu";
import { LanguageDropdown } from "./LanguageDropdown";
import { UserSection } from "./UserSection";

export const Header = () => {
  const { t } = useTranslation();
  const { setCommandMenuOpen } = useStore();
  return (
    <header className="flex items-center justify-between bg-background w-full h-16  px-5 border fixed top-0 left-0 z-10 overflow-hidden">
      <div className="flex items-center">
        <Navigation />
        <a href="/#">
          <SamsvarLogo />
        </a>
      </div>

      <div className="absolute left-1/2 transform -translate-x-1/2 hidden sm:block w-32 sm:w-40 lg:w-[480px]">
        <div className="relative w-full">
          <Search className="absolute left-2.5 top-3 h-4 w-4 opacity-80 text-black" />
          <Input
            type="search"
            placeholder={t("searchPlaceholder")}
            className="w-full rounded-sm pl-8 border-none bg-hoverBackground"
            onClick={() => setCommandMenuOpen(true)}
          />
          <p className="absolute right-2.5 top-1.5 text-gray-400">
            <span className="text-xs">Ctrl</span>
            <span className="text-xs mx-1">+</span>
            <span className="text-xs">K</span>
          </p>
        </div>
      </div>

      <div className="h-full">
        <div className="flex h-full items-center">
          <Search
            className="block sm:hidden size-5 mr-4 opacity-80  text-black hover:cursor-pointer"
            onClick={() => setCommandMenuOpen(true)}
          />
          <LanguageDropdown />
          <Separator orientation="vertical" className="mr-4" />
          <UserSection />
        </div>
      </div>

      <CommandMenu />
    </header>
  );
};
