import type { components } from "./schema";

export type Widget = components["schemas"]["Widget"];
export type WidgetType = components["schemas"]["WidgetType"];
export type MyPageRequest = components["schemas"]["MyPage"];

interface ErrorDetail {
  message: string;
}

interface ErrorResponse {
  _links?: {
    self: { href: string; templated: boolean }[];
  };
  _embedded?: {
    errors: ErrorDetail[];
  };
  message?: string;
}

export const baseUrl = process.env.SERVICE_URL;

export const fetchWidgetsFn = async (companyId: string, userId: string) => {
  const response = await fetch(`${baseUrl}/my-page/${companyId}/${userId}`);
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const createWidget = async (widgetType: WidgetType): Promise<Widget> => {
  const response = await fetch(`${baseUrl}/widget/${widgetType}`);
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const saveWidgetsFn = async (body: MyPageRequest) => {
  const response = await fetch(
    `${baseUrl}/my-page/${body.companyId}/${body.userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  );
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const updateWidgetsFn = async (body: MyPageRequest) => {
  const response = await fetch(
    `${baseUrl}/my-page/${body.companyId}/${body.userId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  );
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const saveOrUpdateWidgetsFn = async (body: MyPageRequest) => {
  const method = body.id ? "PUT" : "POST";
  const response = await fetch(
    `${baseUrl}/my-page/${body.companyId}/${body.userId}`,
    {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  );
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};
