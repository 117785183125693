import { cn, formatDate } from "../../../lib/utils";
import { useStore } from "../../../store/useStore";
import { Separator } from "../../ui/Separator";

interface TaskListItemProps {
  taskName: string;
  taskInfo: string;
  dueDate?: Date;
}

export const TaskListItem = ({
  taskName,
  taskInfo,
  dueDate,
}: TaskListItemProps) => {
  const { user } = useStore();
  const locale = user.primary_locale;

  const isOverdue = (dueDate: Date) => {
    const today = new Date();
    // Set to end of the day
    today.setHours(23, 59, 59, 59);
    return dueDate <= today;
  };

  return (
    <div className="flex flex-col">
      <span className="text-sm font-normal">{taskName}</span>
      <div>
        <span className="text-sm text-secondary">{taskInfo}</span>
        {dueDate && (
          <>
            <span className="text-sm text-secondary"> - </span>
            <span
              className={cn(
                "text-sm",
                isOverdue(dueDate) ? "text-red-700" : "text-secondary",
              )}
            >
              {formatDate(dueDate, locale)}
            </span>
          </>
        )}
      </div>
      <Separator className="my-4" />
    </div>
  );
};
