export const env = (() => {
  switch (process.env.APP_ENV) {
    case "PRODUCTION":
      return "prod";
    case "DEVELOPMENT":
      return "dev";
    default:
      return "local";
  }
})();

export const getJwtCookie = () => {
  const cookies = document.cookie.split(";");
  const jwtCookie = cookies.find((cookie) => cookie.includes("idToken"));
  const value = jwtCookie?.split("=")[1];
  return value;
};
