import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { Widget } from "../api/widgets";

export type User = {
  id: string;
  name: string;
  primary_locale: string;
  permited_language: string[];
  org_id: string;
  org_name: string;
  role: string;
  mfa: string | null;
  phone: string | null;
  email: string;
  principalId: string; // Same as email
  avatar: string;
};

export type Organization = {
  id: string;
  name: string;
  dashboards: string[];
  country: string;
  org_language: string;
};

export type DashboardType = "general" | "url" | "systempage" | "municipality";

export type Dashboard = {
  _id: string;
  account_id: string;
  claims: string[];
  dashboard_id: string;
  dashboard_subtitle: string;
  dashboard_type: DashboardType;
  name: string;
  shouldOpenUrlInNewTab: boolean | null;
  url: string | null;
  icon: string;
};

interface StoreState {
  // Widgets
  customizeEnabled: boolean;
  setCustomizeEnabled: (customizeEnabled: boolean) => void;
  toggleCustomizeEnabled: () => void;
  isEditingWidgetId: string | null;
  setIsEditingWidgetId: (widgetId: string) => void;
  tempWidgets: Widget[] | null;
  setTempWidgets: (widgets: Widget[]) => void;
  updateTempWidget: (id: string, updater: (widget: Widget) => void) => void;
  getTempWidget: (id: string) => Widget | null;

  // User
  user: User | null;
  setUser: (user: User) => void;
  clearUser: () => void;

  // Organization
  organization: Organization | null;
  setOrganization: (organization: Organization) => void;
  clearOrganization: () => void;

  // Search
  commandMenuOpen: boolean;
  setCommandMenuOpen: (commandMenuOpen: boolean) => void;
}

export const useStore = create<StoreState>()(
  devtools(
    immer((set, get) => ({
      // Widgets
      customizeEnabled: false,
      setCustomizeEnabled: (customizeEnabled: boolean) =>
        set((state) => {
          state.customizeEnabled = customizeEnabled;
        }),
      toggleCustomizeEnabled: () =>
        set((state) => {
          state.customizeEnabled = !state.customizeEnabled;
        }),
      isEditingWidgetId: null,
      setIsEditingWidgetId: (widgetId: string) =>
        set((state) => {
          state.isEditingWidgetId = widgetId;
        }),
      tempWidgets: null,
      setTempWidgets: (widgets: Widget[]) =>
        set((state) => {
          state.tempWidgets = widgets;
        }),
      updateTempWidget: (id, updater) =>
        set((state) => {
          const widget = state.tempWidgets.find((widget) => widget.id === id);
          if (widget) {
            updater(widget);
          }
        }),
      getTempWidget: (id) =>
        get().tempWidgets?.find((widget) => widget.id === id) ?? null,

      // User
      user: null,
      setUser: (user: User) =>
        set((state) => {
          state.user = user;
        }),
      clearUser: () => set(() => ({ user: null })),

      // Organization
      organization: null,
      setOrganization: (organization: Organization) =>
        set((state) => {
          state.organization = organization;
        }),
      clearOrganization: () => set(() => ({ organization: null })),

      // Search
      commandMenuOpen: false,
      setCommandMenuOpen: (commandMenuOpen) => set(() => ({ commandMenuOpen })),
    })),
  ),
);
