import { Separator } from "../../ui/Separator";
import { Skeleton } from "../../ui/Skeleton";

interface WidgetStatisticsBoxProps {
  value?: number;
  label: string;
  className?: string;
}

export const WidgetStatisticsBox = ({
  value,
  label,
  className,
}: WidgetStatisticsBoxProps) => {
  return (
    <div
      className={`flex-1 flex flex-row sm:flex-col items-end sm:items-start h-20 p-4 border-t-0 ${className}`}
    >
      {value ? (
        <span className="text-2xl font-bold pr-2 sm:pr-0">{value}</span>
      ) : (
        <Skeleton className="w-full h-full" />
      )}
      <span className="text-sm text-gray-600">{label}</span>
    </div>
  );
};

export const ListItem = ({ value }) => {
  return (
    <div className="flex flex-col">
      <span className="text-sm">{value} for IT department</span>
      <span className="text-sm opacity-30">Department name</span>
      <Separator className="my-4" />
    </div>
  );
};
