import { useWidgets } from "../../hooks/useWidgets";
import { useStore } from "../../store/useStore";
import { CreateWiget } from "./CreateWidget";
import { useTranslation } from "react-i18next";
import { Button } from "@sikrias/samsvar-design-system";

export const CustomizeGroup = () => {
  const { setTempWidgets, setCustomizeEnabled, customizeEnabled } = useStore();
  const { saveWidgets, widgets } = useWidgets();
  const { t } = useTranslation();

  const handleSaveWidgets = () => {
    saveWidgets(widgets);
    setCustomizeEnabled(false);
  };

  const handleCancel = () => {
    setTempWidgets(null);
    setCustomizeEnabled(false);
  };

  const handleCustomize = () => {
    setTempWidgets(widgets);
    setCustomizeEnabled(true);
  };

  return customizeEnabled ? (
    <div className="hidden sm:flex">
      <div className="mr-2">
        <CreateWiget />
      </div>
      <div className="mr-2">
        <Button variant="underline" onClick={handleCancel} data-testid="cancel">
          {t("cancel")}
        </Button>
      </div>

      <Button variant="primary" onClick={handleSaveWidgets}>
        {t("save")}
      </Button>
    </div>
  ) : (
    <div className="hidden sm:flex">
      <Button
        variant="primary"
        onClick={handleCustomize}
        data-testid="customize"
      >
        {t("customize")}
      </Button>
    </div>
  );
};
