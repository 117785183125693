import { Pie, PieChart } from "recharts";
import { Card, CardContent } from "../../ui/Card";
import {
  Chart,
  type ChartConfig,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/Chart";

const chartData = [
  { type: "nonconformity", ncs: 25, fill: "var(--chart-blue-1)" },
  { type: "improvements", ncs: 12, fill: "var(--chart-blue-2)" },
  { type: "hse", ncs: 4, fill: "var(--chart-blue-3)" },
];

const chartConfig = {
  nonconformity: {
    label: "Nonconformity",
  },
  improvements: {
    label: "Improvements",
  },
  hse: {
    label: "HSE",
  },
} satisfies ChartConfig;

const LegendList = () => {
  return (
    <div className="flex flex-col justify-center">
      <h3 className="font-bold mb-3">Nonconformity cases by types</h3>
      <ul className="space-y-2 text-sm">
        {chartData.map((item) => (
          <li key={item.type} className="flex items-center gap-2">
            <span
              className="inline-block size-3 rounded-full"
              style={{ backgroundColor: item.fill }}
            />
            <span>{chartConfig[item.type].label}</span>
            <span className="ml-auto">{item.ncs}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const NonconformityChart = () => {
  return (
    <Card className="flex flex-col border-none shadow-none">
      <CardContent className="flex p-0 justify-center">
        <div className="flex flex-col sm:flex-row">
          <Chart
            config={chartConfig}
            chartId="nonconformity-pie-chart"
            className="mx-auto aspect-square min-h-[200px]"
          >
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel={true} />}
              />
              <Pie
                data={chartData}
                dataKey="ncs"
                nameKey="type"
                innerRadius={50}
                strokeWidth={5}
                isAnimationActive={false}
              />
            </PieChart>
          </Chart>

          <LegendList />
        </div>
      </CardContent>
    </Card>
  );
};
