import { useQuery } from "@tanstack/react-query";
import {
  fetchNonconformityActions,
  fetchNonconformityReports,
  type NonconformityActionsResponse,
  type NonconformityReportsResponse,
} from "../../../api/nonconformity";
import { WidgetStatisticsBox } from "../Templates/WidgetStatisticsBox";
import { useStore } from "../../../store/useStore";

export const NonconformityStatistics = () => {
  const { customizeEnabled } = useStore();
  const { data: actionsResponse } = useQuery<NonconformityActionsResponse>({
    queryKey: ["nonconformity-actions"],
    queryFn: fetchNonconformityActions,
    enabled: !customizeEnabled,
  });

  const { data: ongoingReports } = useQuery<NonconformityReportsResponse>({
    queryKey: ["nonconformity-ongoing-reports"],
    queryFn: fetchNonconformityReports,
    enabled: !customizeEnabled,
  });

  return (
    <div className="flex flex-col sm:flex-row justify-around border rounded-lg border-gray-200">
      <WidgetStatisticsBox
        value={ongoingReports?.reportCount}
        label="Ongoing"
      />
      <WidgetStatisticsBox
        className="sm:border-x-2"
        value={26}
        label="Urgent"
      />
      {
        <WidgetStatisticsBox
          value={actionsResponse?.relatedActions.length}
          label="Actions"
        />
      }
    </div>
  );
};
