import { LucideRefreshCw } from "lucide-react";
import type { ReactNode } from "react";
import {
  FaCompressAlt,
  FaExpandAlt,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { MdDragIndicator } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import type { Widget } from "../../../api/widgets";
import { useStore } from "../../../store/useStore";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/Card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/Tooltip";
import { Button } from "@sikrias/samsvar-design-system";

interface WidgetTemplateProps {
  id: string;
  title: string;
  description?: string;
  children: ReactNode;
  pageLink: string;
  visible: boolean;
  refetchCallback?: () => void;
  isRefetching?: boolean;
}

const HideWidgetOverlay = () => {
  return (
    <div
      className="absolute inset-0 bg-white opacity-50 z-10"
      data-testid="overlay"
    />
  );
};

export const WidgetTemplate = ({
  id,
  title,
  description,
  children,
  pageLink,
  visible,
  refetchCallback,
  isRefetching,
  ...rest
}: WidgetTemplateProps) => {
  const navigate = useNavigate();
  const { customizeEnabled } = useStore();

  return (
    <div className="relative" {...rest}>
      {!visible && <HideWidgetOverlay />}
      <Card className="bg-background flex flex-col">
        <CardHeader className="py-4 min-h-[60px]">
          <CardTitle className="text-base font-extrabold">
            <div className="flex justify-between items-center min-h-9">
              <div className="flex items-center relative">
                {customizeEnabled && (
                  <MdDragIndicator className="absolute -left-5 h-5 w-5 opacity-50" />
                )}
                <span className="text-xl">{title}</span>
              </div>
              <div className="z-20">
                {customizeEnabled ? (
                  <CustomizeWidgetToolbar id={id} />
                ) : (
                  <RefreshWidget
                    refetch={refetchCallback}
                    isRefetching={isRefetching}
                  />
                )}
              </div>
            </div>
          </CardTitle>
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
        <CardContent className="flex-grow">{children}</CardContent>
        <CardFooter className="mt-auto">
          {!customizeEnabled && (
            <div className="flex  w-full">
              <Button
                variant="tertiary"
                className="w-full"
                onClick={() => navigate(pageLink)}
              >
                Go to page
              </Button>
            </div>
          )}
        </CardFooter>
      </Card>
    </div>
  );
};

const CustomizeWidgetToolbar = ({ id }) => {
  const { setIsEditingWidgetId, updateTempWidget, getTempWidget } = useStore();

  const widget = getTempWidget(id);

  const handleCompressClick = (widgetExpanded: Widget["expanded"]) => {
    updateTempWidget(id, (widget) => {
      widget.expanded = widgetExpanded;
    });
  };

  const handleEditClick = () => {
    setIsEditingWidgetId(id);
  };

  const handleHideClick = () => {
    updateTempWidget(id, (widget) => {
      widget.visible = !widget.visible;
    });
  };

  return (
    <div className="flex border rounded-md" data-testid="toolbar">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger data-testid="compress-expand">
            {widget.expanded ? (
              <FaCompressAlt
                className="m-2 size-4"
                onClick={() => handleCompressClick(false)}
              />
            ) : (
              <FaExpandAlt
                className="m-2 size-4"
                onClick={() => handleCompressClick(true)}
              />
            )}
          </TooltipTrigger>

          <TooltipContent side="top" className="bg-white">
            {widget.expanded ? "Compact view" : "Full view"}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger data-testid="edit">
            <GoPencil className="m-2 size-4" onClick={handleEditClick} />
          </TooltipTrigger>

          <TooltipContent side="top" className="bg-white">
            Edit
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger data-testid="hide-show">
            {widget.visible ? (
              <FaRegEye className="m-2 size-4" onClick={handleHideClick} />
            ) : (
              <FaRegEyeSlash className="m-2 size-4" onClick={handleHideClick} />
            )}
          </TooltipTrigger>

          <TooltipContent side="top" className="bg-white">
            {widget.visible ? "Hide" : "Show"}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

interface RefreshWidgetProps {
  refetch: () => void;
  isRefetching?: boolean;
}
const RefreshWidget = ({ refetch, isRefetching }: RefreshWidgetProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <LucideRefreshCw
            className={`size-4 transition-all duration-500 ${isRefetching ? "animate-spin" : ""}`}
            onClick={refetch}
          />
        </TooltipTrigger>
        <TooltipContent side="top" className="bg-white">
          Refresh
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
