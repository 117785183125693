import { ChevronsUpDown, Check } from "lucide-react";
import { createWidget, type Widget, type WidgetType } from "../../api/widgets";
import { cn } from "../../lib/utils";
import { Button, buttonVariants } from "@sikrias/samsvar-design-system";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/Command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "../../hooks/useToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/Dialog";
import { useStore } from "../../store/useStore";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";

export const CreateWiget = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<WidgetType | null>(null);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { setTempWidgets, tempWidgets } = useStore();

  type WidgetTypesLabel = {
    value: WidgetType;
    label: string;
  };

  const widgetTypesWithLabels: WidgetTypesLabel[] = [
    { value: "ACTION", label: "Action" },
    { value: "CHECKLIST", label: "Checklist" },
    { value: "DOCUMENT_HANDLING", label: "Document Handling" },
    { value: "INJURY", label: "Injury" },
    { value: "NON_CONFORMITY", label: "Non-Conformity" },
    { value: "RISK", label: "Risk" },
    { value: "SYSTEM", label: "System" },
  ];

  const mutation = useMutation({
    mutationFn: createWidget,
    onSuccess: (newWidget: Widget) => {
      if (tempWidgets) {
        setTempWidgets([...tempWidgets, newWidget]);
      } else {
        setTempWidgets([newWidget]);
      }
      toast({
        title: t("widgetCreated"),
        description: t("widgetTypeCreated", {
          widgetType: newWidget.widgetType,
        }),
      });
    },
    onError: (error: Error) => {
      toast({
        variant: "destructive",
        title: t("widgetCreationFailed"),
        description: error.message,
      });
    },
  });

  const handleCreate = () => {
    if (value) {
      mutation.mutate(value);
    }
  };

  if (isMobile) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger
        className={buttonVariants({
          variant: "tertiary",
        })}
      >
        {t("createNew")}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("createWidget")}</DialogTitle>
          <DialogDescription>{t("createWidgetDescription")}</DialogDescription>
        </DialogHeader>

        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild={true}>
            <Button
              variant="primary"
              role="combobox"
              aria-expanded={open}
              className="w-[200px] justify-between"
            >
              {value
                ? widgetTypesWithLabels.find(
                    (widgetType) => widgetType.value === value,
                  )?.label
                : t("selectWidget")}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <Command>
              <CommandInput placeholder="Search widgets..." />
              <CommandList className="bg-background">
                <CommandEmpty>No widgets found</CommandEmpty>
                <CommandGroup>
                  {widgetTypesWithLabels.map((widetType) => (
                    <CommandItem
                      key={widetType.value}
                      value={widetType.value}
                      onSelect={(currentValue) => {
                        setValue(
                          currentValue === value
                            ? ("" as WidgetType)
                            : (currentValue as WidgetType),
                        );
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === widetType.value
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                      {widetType.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        <DialogFooter>
          <Button
            variant="tertiary"
            disabled={value === null}
            onClick={handleCreate}
            data-testid="create"
          >
            {t("create")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
