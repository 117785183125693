import { env, getJwtCookie } from "./utils";

const baseUrl = `/${env}/nonconformity`;

export interface RelatedAction {
  actionName: string;
  actionDescription: string;
  actionStatus: "In progress" | "overdue";
  dueDate: string;
  assignee: string[];
  members: string[];
  type: "Nonconformity" | "Improvement";
  caseName: string;
}

export interface NonconformityActionsResponse {
  relatedActions: RelatedAction[];
}

export interface NonconformityReportsResponse {
  reports: unknown[];
  reportCount: number;
}

export const fetchNonconformityActions = async () => {
  const response = await fetch(`${baseUrl}/getAllActions`, {
    method: "POST",
    headers: {
      Authorization: getJwtCookie(),
    },
    body: JSON.stringify({ handler_type: "general" }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
};

export const fetchNonconformityReports = async () => {
  const response = await fetch(`${baseUrl}/getGeneralHandlerReports`, {
    method: "POST",
    headers: {
      Authorization: getJwtCookie(),
    },

    body: JSON.stringify({ filterQuery: null, status: "Ongoing" }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
};
